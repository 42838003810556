import { defineStore } from 'pinia';
import { stringify } from 'qs';
import {
  type Bank,
  type Country,
  type Employee,
  type EmployeeDepartment,
  type SimpleStringModel,
} from '~/models/arbitrary';
import { type BankAccount } from '~/models/clients';

type CountriesCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: Country[] };
};
type BanksCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: Bank[] };
};

export const useGeneralStore = defineStore('general', {
  state: () => ({
    reshowCookies: false,
    loadingCountries: false,
    countriesCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as CountriesCache,
    loadingBanks: false,
    banksCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as BanksCache,
    loadingBankAccounts: true,
    bankAccounts: [] as BankAccount[],
    loadingEmployeeDepartments: false,
    employeeDepartments: [] as EmployeeDepartment[],
    employees: [] as Employee[],
  }),
  getters: {
    availableCurrencies: () => {
      const {
        public: { availableCurrencies },
      } = useRuntimeConfig();

      return availableCurrencies.split(',');
    },
    countries: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.countriesCache[language].loaded) {
        return state.countriesCache[language].data;
      }

      return [];
    },
    banks: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.banksCache[language].loaded) {
        return state.banksCache[language].data;
      }

      return [];
    },
  },
  actions: {
    reshowCookiesBanner() {},
    async getCountries({ force = false }: { force?: boolean } = {}) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (!force && this.countriesCache[language].loaded) {
        return this.countriesCache[language].data;
      }

      this.loadingCountries = true;

      this.countriesCache[language] = {
        loaded: true,
        data: await fetch<Country[]>(`/staticdata/countries/${language}`),
      };

      this.loadingCountries = false;
    },
    async getBanks({ force = false }: { force?: boolean }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (!force && this.banksCache[language].loaded) {
        return this.banksCache[language].data;
      }

      this.loadingBanks = true;

      this.banksCache[language] = {
        loaded: true,
        data: (await fetch<Bank[]>('/staticdata/banks/cz'))
          .filter((b) => b.Code !== '4000')
          .sort((a, b) => a.Code.localeCompare(b.Code)),
      };

      this.loadingBanks = false;
    },
    async getBankAccounts() {
      const fetch = useApiFetch();

      if (this.bankAccounts.length > 0) {
        return;
      }

      this.loadingBankAccounts = true;
      this.bankAccounts = await fetch<BankAccount[]>(
        `/staticdata/bankaccounts${stringify(
          {
            currency: 'CZK',
          },
          { addQueryPrefix: true },
        )}`,
      );
      this.loadingBankAccounts = false;
    },
    async subscribeToNewsletter({ email }: { email: string }) {
      const fetch = useApiFetch();
      const category = 'edu_gallery_nl';
      const domain = 'cz';

      return await fetch(
        `/bloomreach/subscribeto?email=${email}&category=${category}&domain=${domain}`,
        {
          method: 'POST',
        },
      );
    },
    async getEmployeeDepartments() {
      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (this.employeeDepartments.length > 0) {
        return;
      }

      this.loadingEmployeeDepartments = true;

      const response = await fetch<SimpleStringModel>(
        `/staticdata/setting/Departments_${language}`,
      );

      try {
        this.employeeDepartments = JSON.parse(response.Result || '{}').gallery;
      } catch (err) {
      } finally {
        this.loadingEmployeeDepartments = false;
      }
    },
    async getEmployees() {
      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (this.employees.length > 0) {
        return;
      }

      const response = await fetch<SimpleStringModel>(`/staticdata/setting/Employees_${language}`);

      try {
        this.employees = JSON.parse(response.Result || '[]');
      } catch (err) {
        // ignored
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeneralStore, import.meta.hot));
}
